<template>
    <section class="trip-wrap" id="trip-container">
      <div class="btn-wrap">
          <el-button size="small" plain type="primary" @click="saveTrip">保存旅程</el-button>
          <el-button size="small" plain type="danger" @click="publicTrip">发布旅程</el-button>
      </div>
      <div class="trip-left" id="container"></div>
      <div class="trip-right" id="trip"></div>
      <el-dialog
          :title="dialogTitleType[selTripItemType]"
          :visible.sync="dialogGroupVisible"
          width="60%"
          :before-close="handleClose">
        <template v-if="selTripItemType==1">
          <el-form label-width="80px">
            <el-form-item label="群组选择">
              <el-select v-model="tripItemValue1" placeholder="请选择">
                <el-option
                    v-for="item in tripItemOption1"
                    :value-key="item.value"
                    :label="item.label"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
        <template v-else-if="selTripItemType==2">
          <el-form label-width="80px">
            <el-form-item label="发布时间">
              <el-select v-model="tripItemValue2" placeholder="请选择">
                <el-option
                    v-for="item in tripItemOption2"
                    :value-key="item.value"
                    :label="item.label"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
        <template v-else-if="selTripItemType==3">
          <el-form label-width="80px">
            <el-form-item label="素材选择">
              <el-select v-model="tripItemValue3" placeholder="请选择">
                <el-option
                    v-for="item in tripItemOption3"
                    :value-key="item.value"
                    :label="item.label"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </template>
        <template v-else-if="selTripItemType==4">
          <el-form label-width="80px">
            <el-form-item label="素材选择">
              <el-select v-model="tripItemValue4" placeholder="请选择">
                <el-option
                    v-for="item in tripItemOption4"
                    :value-key="item.value"
                    :label="item.label"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

        </template>
        <template v-else-if="selTripItemType==5">
          <el-form label-width="80px">

            <el-form-item label="客服人员">
              <el-select v-model="tripItemValue5" placeholder="请选择客服人员">
                <el-option
                    v-for="item in tripItemOption5"
                    :value-key="item.value"
                    :label="item.label"
                    :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发送备注">
              <el-input type="textarea" v-model="tripItemOtherValue5"></el-input>
            </el-form-item>
          </el-form>
        </template>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogGroupVisible = false">取 消</el-button>
          <el-button type="primary" @click="onGroupSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </section>
</template>

<script>
import defaultSec from '@c/defaultSection'
import {Graph,Addon,Shape} from '@antv/x6'
const { Stencil } = Addon
const { Rect, Circle,Edge,Polygon } = Shape
//node注册
Graph.registerNode(
    'circle-node',
    {
      inherit:'circle',
      width:60,
      height:60,
      markup:[
        {
          tagName:'circle',
          selector:'body'
        },
        {
          tagName: 'text',
          selector:'label'
        },
        {
          tagName: 'text',
          selector: 'name'
        }
      ],
      attrs:{
        body:{strokeWidth: 6, stroke: '#4B4A67'},
        label:{fill: '#ffffff',fontSize:10,refX:.5,refY:.5},
        name:{fill: '#ffffff',fontSize:0,refX:.5,refY:.9}
      }
    }
)
Graph.registerNode(
    'rect-node',
    {
      inherit:'rect',
      width:60,
      height:60,
      markup:[
        {
          tagName:'rect',
          selector:'body'
        },
        {
          tagName: 'text',
          selector:'label'
        },
        {
          tagName: 'text',
          selector: 'name'
        }
      ],
      attrs:{
        body:{strokeWidth: 6, stroke: '#4B4A67',rx:5,ry:5,},
        label:{fill: '#ffffff',fontSize:10,refX:.5,refY:.5},
        name:{fill: '#ffffff',fontSize:0,refX:.5,refY:.9}
      }
    }
)
Graph.registerNode(
    'polygon-node',
    {
      inherit:'polygon',
      width:60,
      height:60,
      markup:[
        {
          tagName:'polygon',
          selector:'body'
        },
        {
          tagName: 'text',
          selector:'label'
        },
        {
          tagName: 'text',
          selector: 'name'
        }
      ],
      attrs:{
        body:{strokeWidth: 6, stroke: '#4B4A67',refPointsResetOffset: '50 35.354, 35.354 50,14.646 50, 0 35.354, 0 14.646,14.646 0, 35.354 0, 50 14.646 ',},
        label:{fill: '#ffffff',fontSize:10,refX:.5,refY:.5},
        name:{fill: '#ffffff',fontSize:0,refX:.5,refY:.9}
      }
    }
)
export default {
  name: "groupManage",
  data(){
    return {
      //群组dialog
      groupOption:[],
      groupValue:{},
      dialogTitleType:{1:'群组配置',2:'发布时间配置',3:'用户素材配置',4:'内部素材配置',5:'发送对象配置'},
      dialogGroupVisible: false,
      //旅程id
      routeId:parseInt(this.$route.params.id),
      routeName:this.$route.params.name,
      tripGroups:[
        {name:'group1',title:'触发条件'},
        {name:'group2',title:'动作类型'},
        {name:'group3',title:'条件属性'},
      ],
      graph:{},stencil:{},
      selNode:{},
      selTripItemType:0,
      links:[],
      tripItemValue1:'',
      tripItemValue2:'',
      tripItemOtherValue2:'1',
      tripItemValue3:'',
      tripItemValue4:'',
      tripItemValue5:'',
      tripItemOtherValue5:'',
      tripItemOption1:[],
      tripItemOption2:[],
      tripItemOption3:[],
      tripItemOption4:[],
      tripItemOption5:[],
    }
  },
  components:{defaultSec},
  beforeRouteLeave (to, from, next) {
    next(false)
    console.log(to,from)
    this.$confirm('离开页面会导致修改内容无法保存，确定离开吗？', '提示', {
      distinguishCancelAndClose: true,
      confirmButtonText: '确定',
    }).then((action)=>{
      console.log(action)
      if(action=='confirm'){
        next()
      }
    })


    // 导航离开该组件的对应路由时调用

    // 可以访问组件实例 `this`

  },
  mounted() {
    let _this = this
    this.initTripItem()
    if(this.routeId){//编辑现有旅程
      this.initTripDetail(this.routeId)
    }
    this.initGraph()
    //旅程选择
    this.graph.on('node:selected', ({ node}) => {
      node.addTools(
          [
            {
              name: 'button-remove',
              args: {
                markup:[
                  {
                    tagName: 'circle',
                    selector: 'body',
                    attrs: {
                      r: 14,
                      stroke: '#fe854f',
                      'stroke-width': 3,
                      fill: '#ffffff',
                      cursor: 'pointer',
                    },
                  },
                  {
                    tagName: 'text',
                    textContent: 'x',
                    selector: 'icon',
                    attrs: {
                      stroke: '#fe854f',
                      fill: '#ffffff',
                      'font-size': 8,
                      'text-anchor': 'middle',
                      'pointer-events': 'none',
                      y: '0.3em',
                    },
                  },
                ],
                x: -10, y: -10
              },
            },{
              name: 'boundary',
              args: {x: 0, y: 0 },
            },{
              name: 'button',
              args: {
                markup:[
                  {
                    tagName: 'circle',
                    selector: 'button',
                    attrs: {
                      r: 14,
                      stroke: '#fe854f',
                      'stroke-width': 3,
                      fill: 'white',
                      cursor: 'pointer',
                    },
                  },
                  {
                    tagName: 'text',
                    textContent: 'Edit',
                    selector: 'icon',
                    attrs: {
                      fill: '#fe854f',
                      'font-size': 8,
                      'text-anchor': 'middle',
                      'pointer-events': 'none',
                      y: '0.3em',
                    },
                  },
                ],
                x: 130, y: -10,
                onClick({cell,view}){
                  _this.tripItemEdit(node)
                }
              },
            },
          ],
      )
    })
    this.graph.on('edge:selected', ({ edge }) => {
      console.log(edge)
      edge.addTools(
          [
            {
              name: 'button-remove',
              args: { x: 10, y: 10 },
            },
          ],
      )
    })
    this.graph.on('node:unselected', ({ node }) => {
      if (node.hasTool('button-remove')) {
        node.removeTool('button-remove')
      }
      if (node.hasTool('button')) {
        node.removeTool('button')
      }if (node.hasTool('boundary')) {
        node.removeTool('boundary')
      }
    })
  },
  methods:{
    //初始化旅程类型
    initTripItem(){
      this.$apiGet('lechun-cms/scrmAction/getActionList',{}).then(res => {
        let trip = {
          trip1:[],
          trip2:[],
          trip3:[],
        }
        res.map(item=>{
          if(item.actionClass){
            trip['trip'+item.actionClass].push(this.creatTripItem({item}))
          }
        })
        this.stencil.resizeGroup('group1', {height: Math.ceil(trip.trip1.length/2)*100 }).load(trip.trip1, 'group1')
        this.stencil.resizeGroup('group2', {height: Math.ceil(trip.trip2.length/2)*100 }).load(trip.trip2, 'group2')
        this.stencil.resizeGroup('group3', {height: Math.ceil(trip.trip3.length/2)*100 }).load(trip.trip3, 'group3')

      })
    },
    //初始化旅程（id）
    initTripDetail(id){
      this.$apiGet('lechun-cms/scrmRoute/getRouteLinks',{ routeId:id}).then(res => {
        let cells = []
        let newTripData = res
        this.links=res
        res.map((item,index)=>{
          res.map(item1=>{
            if(item.id==item1.parentId){
              newTripData.push({type:'edge',source:(item.id).toString(),target:(item1.id).toString()})
            }
          })
        })
        newTripData.map((item,index)=>{
          if(!item.type){
            let node = this.creatTripItem({editItem:item})
            cells.push(node)
          }else{
            cells.push(this.graph.createEdge({
              source:item.source,
              target:item.target
            }))
          }
        })
        this.graph.resetCells(cells)
      })
    },
    //初始化graph
    initGraph(){
      const graph = new Graph({
        container: document.getElementById('container'),
        grid: {
          size: 10,      // 网格大小 10px
          visible: true, // 渲染网格背景
        },
        panning: true,
        connecting: {
          snap: true,
          connector:'rounded',
          router:'metro',
          allowBlank:false,
          allowLoop:false,
          allowMulti:'withPort',
        },
        selecting: {
          enabled: true
        },
      });
      graph.resize('100%')
      let _this = this
      const stencil = new Stencil({
        target:graph,
        title:'',
        stencilGraphWidth: 240,
        stencilGraphHeight: 180,
        groups: this.tripGroups,
        getDropNode(node) {
          let newNode = _this.creatTripItem({node})
          return newNode
        },
      })
      this.graph = graph
      this.stencil = stencil
      document.getElementById('trip').appendChild(stencil.container)
    },
    //创建node
    creatTripItem({item=null,node=null,editItem=null}){
      //actionClass判断分组图形
      let shapeAction = {1:'circle-node',2:'rect-node',3:'polygon-node'}
      //actionType判断图形颜色
      let actionTypeColor = {
        1:'#FE854F',
        2:'#e85656',
        3:'#31D0C6',
        4:'#16a7ff',
        5:'#59b438',
      }
      let proTyp = {}
      if(node){
        proTyp = {
          shape:node.shape,
          width:node.size().width*2,
          height:node.size().height*2,
          attrs:{
            item:{...node.attrs.item},
            body:node.attrs.body,
            label:{text:node.attrs.item.actionName,fontSize:16,refY:.4},
            name:{text:'暂未设置',fontSize:12,refY:.6},
          },
          ports: {
            groups:{
              in:{
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: '#31d0c6',
                    strokeWidth: 2,
                    fill: '#fff',
                  },
                },
              },
              out:{
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: '#31d0c6',
                    strokeWidth: 2,
                    fill: '#fff',
                  },
                },
                position:{name:'right'}
              }
            },
            items:[
              // {id:'port1',group:'in'},
              {id:'port1',group:'out'},
            ]
          }
        }

      }else if(editItem){
        proTyp = {
          id:editItem.id,
          shape:shapeAction[editItem.actionClass||1],
          width:120,
          height:120,
          x:editItem.x,
          y:editItem.y,
          attrs:{
            item:{...editItem},
            body:{fill:actionTypeColor[editItem.actionType]},
            label:{text:editItem.actionName,fontSize:16,refY:.4},
            name:{text:editItem.actionDetailName,fontSize:12,refY:.6},
          },
          ports: {
            groups:{
              in:{
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: '#31d0c6',
                    strokeWidth: 2,
                    fill: '#fff',
                  },
                },
              },
              out:{
                attrs: {
                  circle: {
                    r: 6,
                    magnet: true,
                    stroke: '#31d0c6',
                    strokeWidth: 2,
                    fill: '#fff',
                  },
                },
                position:{name:'right'}
              }
            },
            items:[
              // {id:'port1',group:'in'},
              {id:'port2',group:'out'},
            ]
          }
        }
      }else{
        proTyp = {
          shape:shapeAction[item.actionClass||1],
          attrs:{
            item:{...item},
            body:{fill:actionTypeColor[item.actionType]},
            label:{text:item.actionName}
          }
        }
      }
      return this.graph.createNode({
        ...proTyp
      })
    },
    //初始化弹窗选项
    initDialogOptions(type){
      if(type==1){
        this.$apiGet('lechun-cms/scene/getSceneOption',{}).then(res => {
          this.tripItemOption1 = res;
        })
      }else if(type==2){
        this.$apiGet('lechun-cms/scrmCron/getCronList',{}).then(res => {
          this.tripItemOption2 = res;
        })
      }else if(type==3){
        this.$apiGet('lechun-cms/scrmMaterial/getOptionMaterialList',{'materialClass':1}).then(res => {
          this.tripItemOption3 = res;
        })
      }else if(type==4){
        this.$apiGet('lechun-cms/scrmMaterial/getOptionMaterialList',{'materialClass':2}).then(res => {
          this.tripItemOption4 = res;
        })
      }else if(type==5){
        //加载客服列表
      }
    },
    //旅程编辑按钮点击事件
    tripItemEdit(node){
      console.log(node)
      //actionType 旅程类型 1群组，2定时，3客户素材，4内部素材，5发企微客服
      this.selNode = node
      let actionType = node.attrs.item.actionType
      this.selTripItemType = actionType
      this.initDialogOptions(actionType)
      this.dialogGroupVisible = true
    },
    //dialog选择提交事件
    onGroupSubmit(){
      let actionType = this.selTripItemType
      let tripItem = this['tripItemValue'+actionType]
      if(!tripItem.hasOwnProperty('value')){
        this.$message({
          message: '值不能为空哦~~',
          type: 'warning'
        });
        return false
      }
      this.selNode.attr({
        name:{text:tripItem.label||'暂未配置'},
        item:{actionDetailId:tripItem.value,actionDetailName: tripItem.label}
      })
      this.dialogGroupVisible = false
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    //保存旅程
    saveTrip(){
      let saveRule = []
      let tripNodes = this.graph.getNodes().map(item=>{
        item.attr({
          item:{
            id:item.id,
            routeId:this.routeId,
            x:item.position().x,
            y:item.position().y,
          }
        })
        return item.attrs.item
      })
      let tripEdges = this.graph.getEdges()
      tripNodes.map(item=>{
        item.parentId = null
        tripEdges.map(item1=>{
          if(item1.target.cell == item.id){
            item.parentId = item1.source.cell
          }
        })
        return item
      })
      //判断旅程是否符合规则
      tripNodes.map(item=>{
        if(item.actionType!=1&&!item.parentId){
          saveRule.push(1)
        }
        if(!item.hasOwnProperty('actionDetailId')||item['actionDetailId']==''){
          //saveRule.push(1)
        }
      })
      // if(saveRule.length>0){
      //   this.$message({
      //     message: '旅程不符合规则哦~~',
      //     type: 'warning'
      //   });
      //   return false
      // }
      console.log(tripNodes,tripEdges)//设计的旅程（和修改旅程返回的接口参数一致）

      this.$apiGet('lechun-cms/scrmRoute/saveRouteLinks',{routeLink:encodeURIComponent(JSON.stringify(tripNodes))}).then(res => {
        let _this = this
        this.$message({
          message: '保存成功',
          type: 'success',
          onClose(){
            _this.$router.push({path: '/routelist', name: 'routeList'})
          }
        });
        console.log(res)
      })
    },
    //发布旅程
    publicTrip(){
      this.$apiGet('lechun-cms/scrmRoute/deployRoute',{routeId:this.routeId}).then(res => {

        this.$message({
          message: '发布成功',
          type: 'success'
        });
        console.log(res)
      })
    },

  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
  .add-wrap{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .trip-wrap{
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    .btn-wrap{
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 9;
    }
    .trip-left{
      flex: 1;border: 1px solid #666666;
      background: #ffffff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .trip-right{
      position: relative;
      width: 220px;
      background: #1e2336;
      color:#ffffff;
      padding:10px
    }
    .right-title{
      font-size: 14px;
    }
    .el-collapse{
      border: none;
    }
    .el-collapse-item__header{
      background-color: #143457;
      color: #ffffff;
      border: none;
    }
    .el-collapse-item__wrap{
      border: none;
      background-color: transparent;
    }
    .right-circle{
      display: block;
      width: 60px;
      height: 60px;
      background: #55a532;
      color: #ffffff;
      border-radius: 40px;
      text-align: center;
      line-height: 60px;
    }

  }

</style>